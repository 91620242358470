export default class IndicationPriceCalculator {
    /**
    * @param {Element|undefined} formElement
    */
    formElement

    /**
    * @type {Object<string, Object<string, number>>} prices - prices[buildingHeatedArea][waterHeating]
    */
    static prices = {
        '0_100': {
            'yes': 10500,
            'no': 9000,
        },
        '101_150': {
            'yes': 10500,
            'no': 9000,
        },
        '151_200': {
            'yes': 12000,
            'no': 10000,
        },
        '201_300': {
            'yes': 13000,
            'no': 10500
        }
    }

    static pricesMt2985 = {
        '0_100': {
            'yes': {
                'price': 10500,
                'kw': 9
            },
            'no': {
                'price': 9000,
                'kw': 9
            }
        },
        '101_150': {
            'yes': {
                'price': 10500,
                'kw': 12
            },
            'no': {
                'price': 9000,
                'kw': 12
            }
        },
        '151_200': {
            'yes': {
                'price': 12000,
                'kw': 16
            },
            'no': {
                'price': 10000,
                'kw': 14
            }
        },
        '201_300': {
            'yes': {
                'price': 13000,
                'kw': 16
            },
            'no': {
                'price': 10500,
                'kw': 16
            }
        }
    }

    constructor(id) {
        this.formElement = document.querySelector(`[data-calculator-id="${id}"]`)
        this.formElement.addEventListener('change', this.updatePrice.bind(this))

        // Listen for the custom event 'vwoMt2985Enabled'
        window.addEventListener('vwoMt2985Enabled', () => {
            const kwContainer = this.formElement.querySelector('[data-result-kw-container]');
            const resultBox = this.formElement.querySelector('.calculator__result-box');

            if (kwContainer) {
                kwContainer.style.display = 'block';
                resultBox.classList.add('calculator__result-box--mt-2985');
            }
            this.updatePrice()
        });
    }

    updatePrice() {
        const resultElement = this.formElement.querySelector('[data-result-price]')
        const kwElement = this.formElement.querySelector('[data-result-kw]');
        const buildingHeatedArea = this.formElement.querySelector('[name=building_heated_area]').value
        const waterHeating = this.formElement.querySelector('[name=water_heating]').value

        let price, kw;

        if(window.vwoMt2985) {
            price = IndicationPriceCalculator.pricesMt2985[buildingHeatedArea][waterHeating].price;
            kw = IndicationPriceCalculator.pricesMt2985[buildingHeatedArea][waterHeating].kw;
        }
        else
            price = IndicationPriceCalculator.prices[buildingHeatedArea][waterHeating]

        resultElement.innerHTML = Intl.NumberFormat(
            'de-DE',
            {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }
        ).format(price);

        kwElement.innerHTML = `${kw} kW`;

        return price;
    }
}
